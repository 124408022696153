<template>
  <div>
    <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headerType}} {{pageTitle}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="brnadForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >

          <!-- Size -->
          <validation-provider
            #default="validationContext"
            name="Size"
            rules="required"
          >
            <b-form-group
              label="Size"
              label-for="size"
            >
              <b-form-input
                id="size"
                v-model="size"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Size"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Oil Size -->
          <validation-provider
            #default="validationContext"
            name="NumberLiter"
            rules="required"
          >
            <b-form-group
              label="Number Of Liter"
              label-for="NumberLiter"
            >
              <b-form-input
                id="number_liter"
                v-model="number_liter"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Number of Liter"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

  
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }} 
            </b-button>
            <!-- spinner -->
            <b-button
              v-if="loading"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>

  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BCardText, BMedia, BAvatar, BSpinner,  BRow, BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { watch } from '@vue/composition-api'
import DataService from './data/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    vSelect,
    BRow, 
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSlider: {
      type: Object,
      default: () => {},
    },
    companyType: {
      type: [Array, Object],
      required: true
    },
    pageTitle: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      required,
      alphaNum,
      email,
      headerType: 'Add',
      size:'',
      number_liter: '',
      id: '',
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.dataSlider).length === 0) {
        this.headerType = 'Add'
        this.initValues()
      }else {        
        this.id = this.dataSlider.id
        this.size = this.dataSlider.size
        this.number_liter = this.dataSlider.number_liter
        this.headerType = 'Edit'
        // this.repareType(this.dataSlider.type)
        // this.initValues()
      }
    }
  },
  methods: {
    initValues() {
      if(this.dataSlider.id) return
        this.id = null
        this.size = null
        this.number_liter = null
        this.allerrors = {}
    },
    onSubmit(){
      if(!this.id){
        this.sendData()
      }else{
        this.editData();
      }
    },
    prepareData(){
      return {
        size: this.size,
        number_liter: this.number_liter
      }
    },
    sendData(){
      this.loading = true;

        DataService.create(this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$parent.refreshData(response.data.data);
            // this.$emit('refreshData('+response.data.data+')');
            
            this.$emit('update:is-add-new-user-sidebar-active', false)
          }else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    editData(){
      this.loading = true;

        DataService.update(this.id, this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$parent.refreshData(response.data.data);
            // this.$emit('refreshData('+response.data.data+')');
            
            this.$emit('update:is-add-new-user-sidebar-active', false)
          }else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    repareType(type){
      let list=[];
      type.forEach(function(value, key) {
        list.push(value.type);
      });
      return list
    }
    
  },
  setup() {
    const name = ref(null)
    const name_ar = ref(null)
    let loading = ref(false)
    const allerrors = ref(null)
    // const onSubmit = () => {
    //   console.log(loading.value == ref(true))
    //   loading.value = true;
    //   let data = {
    //     name: name.value, 
    //     name_ar: name_ar.value,
    //   }
    //     DataService.create(data)
    //     .then(response => {
    //       loading.value = false
    //       let responseCode = response.data.responseCode
    //       if(responseCode == 100){
    //         console.log(response.data.data)
    //         emit('update:items', response.data.data)
    //         emit('update:is-add-new-user-sidebar-active', false)
    //       }else if(responseCode == 102){
    //         toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'Error',
    //             icon: 'BellIcon',
    //             text: response.data.responseDescription,
    //             variant: 'danger'
    //           },
    //         })
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       loading.value = false
                  
    //       if (error.response.data.responseCode == 102) {
    //         allerrors = error.response.data.error
    //       }
    //       let message = Error
    //       if (error.response !== undefined) {
    //         message = error.response.data.message;
    //       }
          
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Error',
    //           icon: 'BellIcon',
    //           text: message,
    //           variant: 'danger'
    //         },
    //       })
    //     });
      
    // }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button
      loading,
      name,
      name_ar,
      allerrors
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>