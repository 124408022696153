import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/machineSize");
  }
  get(id) {
    return axios.get(`/machineSize/${id}`);
  }
  create(data) {
    return axios.post("/machineSize", data);
  }
  update(id, data) {
    return axios.put(`/machineSize/${id}`, data);
  }
  delete(id) {
    return axios.delete(`/machineSize/${id}`);
  }
  deleteAll() {
    return axios.delete(`/machineSize`);
  }
  findByTitle(title) {
    return axios.get(`/machineSize?title=${title}`);
  }
  changeStatus(id) {
    return axios.get(`changeMachineSizeStatus/${id}`);
  }
}
export default new DataService();